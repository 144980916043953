<template>
  <v-expansion-panels mandatory flat>
    <!-- ---------------------------------------------------------- -->
    <!-- List de usuários encontrados com o cpf e renach informado  -->
    <!-- ---------------------------------------------------------- -->
    <v-expansion-panel>
      <v-expansion-panel-header expand-icon='mdi-menu-down'>
        <v-row>
          <v-tooltip bottom>
            <template #activator='{ on }'>
              <v-chip
                outlined
                :color='"warning"'
                class='ml-2'
                v-on='on'
              >
                REGISTRO LEGADO
              </v-chip>
            </template>
            REGISTRO LEGADO
          </v-tooltip>
        </v-row>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-container fluid class='pb-5'>
          <!-- ------------------------------------------------- -->
          <!-- Imagem do candidato -->
          <!-- ------------------------------------------------- -->
          <v-row v-if='items.filter(i => i.isFace).length > 0' no-gutters class='pb-5' align='center' justify='center'>
            <v-card v-for='(face, index) in items.filter(i => i.isFace)' :key='index' flat height='350' color='#fff'>
              <v-container fluid fill-height>
                <v-row align='center' justify='center'>
                  <v-img
                    :src='`data:image/charset=UTF-8;png;base64,${face.base64}`'
                    class='grey lighten-2'
                    max-width='350'
                    max-height='350'
                    min-height='50'
                    @error='imageLoadError(face)'
                  >
                    <template #placeholder>
                      <v-row
                        class='fill-height ma-0'
                        align='center'
                        justify='center'
                      >
                        <v-progress-circular
                          v-if='!face.error'
                          indeterminate
                          color='grey lighten-5'
                        />
                        <div v-else class='primary--text'>
                          Não foi possível carregar a imagem
                        </div>
                      </v-row>
                    </template>
                  </v-img>
                </v-row>
              </v-container>

              <v-card-actions style='margin-top: -30px'>
                <v-row no-gutters align='center' justify='center'>
                  <v-chip color='primary'>
                    {{getImageText(face)}}
                  </v-chip>
                </v-row>
                <v-row no-gutters align='center' justify='center'>
                  <v-chip>
                    {{formattedDateWithTime(face.dt_aquisicao)}}
                  </v-chip>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-row>

          <v-row no-gutters align='center' justify='center' class='mt-5'>
            <v-tabs v-model='tab' centered>
              <v-tab>
                {{$t('SIGNATURE')}}
              </v-tab>
              <v-tab>
                {{$t('FINGERPRINTS')}}
              </v-tab>
            </v-tabs>
          </v-row>

          <v-row no-gutters align='center' justify='center' class='mt-10'>
            <v-tabs-items v-model='tab'>
              <!-- ------------------------------------------------- -->
              <!-- Aba de Assinatura -->
              <!-- ------------------------------------------------- -->
              <v-tab-item>
                <v-row v-if='items.filter(i => i.isSignature).length > 0' no-gutters align='center' justify='center' class='mt-5 pb-5'>
                  <v-card v-for='(signature, index) in items.filter(i => i.isSignature)' :key='index' flat height='350' color='#fff'>
                    <v-container fluid fill-height>
                      <v-row align='center' justify='center'>
                        <v-img
                          :src='`data:image/charset=UTF-8;png;base64,${signature.base64}`'
                          class='grey lighten-2'
                          max-width='350'
                          max-height='350'
                          min-height='50'
                          @error='imageLoadError(signature)'
                        >
                          <template #placeholder>
                            <v-row
                              class='fill-height ma-0'
                              align='center'
                              justify='center'
                            >
                              <v-progress-circular
                                v-if='!signature.error'
                                indeterminate
                                color='grey lighten-5'
                              />
                              <div v-else class='primary--text'>
                                Não foi possível carregar a imagem
                              </div>
                            </v-row>
                          </template>
                        </v-img>
                      </v-row>
                    </v-container>

                    <v-card-actions style='margin-top: -30px'>
                      <v-row no-gutters align='center' justify='center'>
                        <v-chip color='primary'>
                          {{getImageText(signature)}}
                        </v-chip>
                      </v-row>
                      <v-row no-gutters align='center' justify='center'>
                        <v-chip>
                          {{formattedDateWithTime(signature.dt_aquisicao)}}
                        </v-chip>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-row>
              </v-tab-item>

              <!-- ------------------------------------------------- -->
              <!-- Aba de Biometrias -->
              <!-- ------------------------------------------------- -->
              <v-tab-item>
                <v-row no-gutters>
                  <v-col
                    v-for='(image, imageIndex) in items.filter(i => !i.isFace && !i.isSignature)'
                    :key='imageIndex'
                    class='d-flex child-flex pb-5'
                    cols='12'
                    sm='12'
                    md='4'
                  >
                    <v-card flat height='350' color='#fff'>
                      <v-container fluid fill-height>
                        <v-row align='center' justify='center'>
                          <v-img
                            :src='`data:image/charset=UTF-8;png;base64,${image.base64}`'
                            class='grey lighten-2'
                            max-width='350'
                            max-height='350'
                            min-height='50'
                            @error='imageLoadError(image)'
                          >
                            <template #placeholder>
                              <v-row
                                class='fill-height ma-0'
                                align='center'
                                justify='center'
                              >
                                <v-progress-circular
                                  v-if='!image.error'
                                  indeterminate
                                  color='grey lighten-5'
                                />
                                <div v-else class='primary--text'>
                                  Não foi possível carregar a imagem
                                </div>
                              </v-row>
                            </template>
                          </v-img>
                        </v-row>
                      </v-container>

                      <v-card-actions style='margin-top: -30px'>
                        <v-row no-gutters align='center' justify='center'>
                          <v-chip color='primary'>
                            {{getImageText(image)}}
                          </v-chip>
                        </v-row>
                        <v-row no-gutters align='center' justify='center'>
                          <v-chip>
                            {{formattedDateWithTime(image.dt_aquisicao)}}
                          </v-chip>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  import { format, parseISO } from 'date-fns';
  import ptBr from 'date-fns/locale/pt-BR';

  export default {
    name: 'EntryDetailsLegacyDialog',
    props: {
      items: {
        type: Array,
        default: function () {
          return [];
        },
      },
    },
    data: function () {
      return {
        tab: 0,
      };
    },
    methods: {
      imageLoadError: function (image) {
        image.error = true;
        this.$forceUpdate();
      },
      getImageText: function (image) {
        if (image.isFace) {
          return 'Face';
        }
        if (image.isSignature) {
          return 'Assinatura';
        }
        if (image.isRightThumb) {
          return 'Polegar direito';
        }
        if (image.isRightIndex) {
          return 'Indicador direito';
        }
        if (image.isRightMiddle) {
          return 'Médio direito';
        }
        if (image.isRightRing) {
          return 'Anelar direito';
        }
        if (image.isRightPinky) {
          return 'Mínimo direito';
        }
        if (image.isLeftThumb) {
          return 'Polegar esquerdo';
        }
        if (image.isLeftIndex) {
          return 'Indicador esquerdo';
        }
        if (image.isLeftMiddle) {
          return 'Médio esquerdo';
        }
        if (image.isLeftRing) {
          return 'Anelar esquerdo';
        }
        if (image.isLeftPinky) {
          return 'Mínimo esquerdo';
        }
        return '';
      },
      formattedDate: function (value) {
        return value && format(parseISO(value), 'dd/MM/yyyy', { locale: ptBr });
      },
      formattedDateWithTime: function (value) {
        return value && format(parseISO(value), 'dd/MM/yyyy hh:mm:ss', { locale: ptBr });
      },
    },
  };
</script>
